import * as axios from "axios";

/**
 *
 * APIURL
 *
 *
 */
const getAPIURL = () => {
  return `${process.env.REACT_APP_API}`;
};

/**
 *
 * 取得員工資料 by Line Id
 *
 */
const getEmpInfoByLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/bylineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 驗證登入 綁定 LineId
 *
 */
const bindLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/bind/lineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

export { getEmpInfoByLineId, bindLineId };
