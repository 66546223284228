import React, { useContext, useEffect, useRef, useState } from "react";
import liffHelper from "../../Utils/liffHelper";
import { getEmpInfoByLineId, bindLineId } from "../../Services/APIService";
import loginBg from "../../assets/images/login_bg.jpg";

import { Form, Icon, Input, Button, message } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  SendOutlined,
} from "@ant-design/icons";

import * as _ from "lodash";
import "./Login.scss";

export default function Login() {
  const [empId, setEmpId] = useState(); //工號
  const [password, setPassword] = useState(); //密碼

  const [newPassword, setNewPassword] = useState(); //新密碼
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(); //確認新密碼

  const [lineProfile, setLineProfile] = useState({}); //工號

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    //console.log("==> loginc comp componentDidMount");

    async function fetchData() {
      // 取得 Line 使用者資訊
      const profile = await liffHelper.getUserProfile();

      setLineProfile(profile);

      //檢查是否已綁定
      const checlkBindedResult = await getEmpInfoByLineId({
        lineId: profile.userId,
      });

      if (_.get(checlkBindedResult, "RESULT") === "OK") {
        //已綁定
        message.success("已綁定工號成功!");

        //發送訊息
        const messageObj = [
          {
            type: "text",
            text: "綁定工號完成",
          },
        ];

        await liffHelper.sendMessages(messageObj);

        //關閉 LIFF App Window
        await liffHelper.closeLiffWindow();

        return;
      }

      message.success(`${_.get(profile, "displayName")} 請綁定工號!`);
    }

    // do fetchData (取得 Line 使用者資訊)
    fetchData();

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  /**
   *
   * 送出事件
   *
   *
   */
  const onSubmit = async () => {
    //送出欄位檢查
    if (_.size(empId) != 7) {
      message.error("工號長度不等於 7");
      return false;
    }

    if (password != "0000" && _.size(password) < 8) {
      message.error("密碼長度小於 8");
      return false;
    }

    if (password === "0000" && _.size(newPassword) < 8) {
      message.error("新密碼長度小於 8");
      return false;
    }

    if (password === "0000" && newPassword !== newPasswordConfirm) {
      message.error("新密碼跟確認新密碼不符");
      return false;
    }

    // 送出到後端驗證帳密
    const obj = {
      empId: _.toUpper(empId),
      password,
      newPassword,
      lineId: lineProfile.userId,
      lineDisplayName: lineProfile.displayName,
      linePictureUrl: lineProfile.pictureUrl,
    };

    const bindLineIdRes = await bindLineId(obj);
    const { RESULT } = bindLineIdRes;
    if (RESULT === "OK") {
      message.success("綁定成功");
      //發送訊息
      const messageObj = [
        {
          type: "text",
          text: "綁定工號完成",
        },
      ];

      await liffHelper.sendMessages(messageObj);

      //關閉 LIFF App Window
      await liffHelper.closeLiffWindow();

      return;
    } else {
      message.error("登入失敗");
    }
  };

  /**
   *
   *
   * 是否顯示新密碼輸入欄位
   *
   */
  const isShowNewPasswordInput = () => {
    return password === "0000" ? true : false;
  };

  return (
    <div className="login-comp">
      {/* 背景圖檔 */}
      <div className="bg-image">
        {" "}
        <img src={loginBg} alt="" />
      </div>

      {/* header */}
      <div className="header">
        <div className="title">CIRSOLAR</div>
        <div className="sub-title">綁定工號</div>
      </div>

      {/* login form */}
      <div className="login-form">
        <div className="input-area">
          {/* 員工工號 */}
          <div class="label">員工工號</div>
          <div class="input-field">
            <Input
              class="input"
              type="text"
              placeholder="請輸入工號, 不分大小寫"
              onChange={(e) => setEmpId(e.target.value)}
              defaultValue={empId}
              allowClear
            />
          </div>

          {/* 登入密碼 */}
          <div class="label" id="new-pwd-area">
            登入密碼
          </div>
          <div class="input-field">
            <Input.Password
              placeholder="請輸入登入密碼, 初始密碼為 0000"
              onChange={(e) => {
                document.getElementById("new-pwd-area").scrollIntoView(true);
                setPassword(e.target.value);
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </div>

          {/* ----------------------- */}

          {isShowNewPasswordInput() && (
            <React.Fragment>
              {/* 新密碼 */}
              <div class="label">新密碼(長度8碼以上)</div>
              <div class="input-field">
                <Input.Password
                  placeholder="請輸入新密碼(長度8碼以上)"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </div>

              {/* 確認新密碼 */}
              <div class="label">確認新密碼</div>
              <div class="input-field">
                <Input.Password
                  placeholder="請輸入新密碼(長度8碼以上)"
                  onChange={(e) => setNewPasswordConfirm(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </div>
            </React.Fragment>
          )}

          {/* 送出按鈕 */}
          <div class="label"> </div>
          <div className="input-field">
            <Button
              type="primary"
              block
              icon={<SendOutlined />}
              onClick={() => onSubmit()}
            >
              送出
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
