import Login from "./components/Login/Login.jsx";

const rootPath = process.env.PUBLIC_URL;

//  拿掉  exact 避免 LIFF 白畫面
const routes = [
  {
    path: `/`,
    component: Login,
  },

  {
    path: `/login`,
    component: Login,
  },
];

export default routes;
export { rootPath };
